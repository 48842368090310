import React from 'react'
import {Helmet} from "react-helmet";

function MastermindTechText() {
  return (
    <div>
     <Helmet>
      <meta name="description" content="Our vision is to provide excellent knowledge and skills to students who participate in our training center, and many opportunities that will arise. We are devoted to providing solutions to enterprises, Governmental Sectors, Private Institutes, organizations, schools, Universities, hospital systems etc. Overall, our main goal is to be a stable technology company that aspires to solutions." />
    </Helmet> 

      <p>
        <h3>Nature of the company</h3>
        The team is a dedicated group of individuals who specialize in Computer field and Business Information System. Executives have all skills that are used to broaden the company, every special skill has its own place in MastermindTech. The young, albeit ambitious, can have a place in MMT. MastermindTech encourages new ideas and projects that can help improve the simplicity of life around the world. Cared for deeply by the executives, the goal for MastermindTech will broaden the horizon for many of those who wish to find the correct path of success, as well as offering many of our audiences.
          
   {/*       <p style={{marginTop: '1em'}}>
         And the company automatically will verify the client KYC information and authorize the client registration. The client has known that without their KYC information the company cannot authorize the account registration. If The client wants to market their real-estate business on our site. They must top up the account this way so they can post on the real-estate business site. The maximum for the post will be the best of the client account top up, for example if the client tops up 100sdg on the account, the client can post 100 posts on the real-estate site. The post is the best on how the client tops up the account.
         </p> */}

      </p>


     <p style={{marginTop: '1em'}}>
     <h3>MastermindTech Mission</h3>
     MMT Mission is to establish an everlasting Tech Institute that will provide a trainee a good aspect in practical learning and Innovation. Moreover, to provide solutions that will benefit the society and business owners.  We focused on practical training, so that the trainee will have firsthand experience in handling software as well as being familiar with the programming languages that they learn in the center.
     </p>


     <p style={{marginTop: '1em'}}>
        <h3>MastermindTech Vision</h3>
        Our vision is to provide excellent knowledge and skills to students who participate in our training center, and many opportunities that will arise. We are devoted to providing solutions to enterprises, Governmental Sectors, Private Institutes, organizations, schools, Universities, hospital systems etc. Overall, our main goal is to be a stable technology company that aspires to solutions.
     </p>


     <p style={{marginTop: '1em'}}>
     <h3>SOLUTION</h3>
        MMT solution is mainly targeting all sectors of services.  We are devoted to providing solutions to enterprises, Governmental Sectors, Private Institutes, organizations, schools, Universities, hospital systems etc. Overall, our main goal is to be a stable technology company that aspires to solutions.
     </p>



    </div>
  )
}

export default MastermindTechText