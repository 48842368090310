import React from 'react'
import FooterNav from './Footer/FooterNav'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

function Footer() {

      const d = new Date().getFullYear()
  return (
    <footer>
       <div className='FooterNav-big-con'>
       <FooterNav
         />

       </div>
       

       {
        <div className='footerATdate'>
          <span>Powered By MastermindTech.</span>
           <span> All Rights Reserved </span>
           <span style={{fontSize:'13px'}}>
             (UptoDate:{d})
           </span>
          
        </div>
       }
      
    </footer>
  )
}

export default Footer