import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import {Link} from 'react-router-dom'

export default function FooterNav(props) {
  const [open, setOpen] = React.useState(true);

  
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className='FooterNav-Con'>

    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'black' }}
      className="FooterNavCard-con"
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader sx={{backgroundColor:'rgba(255,255,255,0.5)'}} component="div" id="nested-list-subheader">
            <div style={{display:'flex',alignItems:"center"}}>
            <DisplaySettingsIcon
            sx={{color:'black',fontSize:'2em'}}
           /> 
           <div>Services</div>
            </div>
        </ListSubheader>
      }
    >
    {/*   <ListItemButton>
        <ListItemIcon>
          <RoofingIcon
           sx={{color:'white'}}
          />
        </ListItemIcon>
        <ListItemText primary="RealEstate" />
      </ListItemButton> */}

   {/*    <ListItemButton>
        <ListItemIcon>
          <SchoolIcon
            sx={{color:'white'}}
           />
        </ListItemIcon>
        <ListItemText primary="StudyAbroad" />
      </ListItemButton> */}


      <ListItemButton>
        <ListItemIcon>
          <CodeOffIcon
            sx={{color:'white'}}
           />
        </ListItemIcon>

        <Link style={{textDecoration: 'none',color:"white"}} to="https://institute.mmt-ng.com/"><ListItemText sx={{coloR:"white"}} primary="MMT-Center" /></Link>
      </ListItemButton>



     
    </List>

    </div>
  );
}