import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import NavDrawer from './NavDrawer';
import RealEstate from './RealEstate/RealEstate';
import SolutionDrop from './StudyAbroad/SolutionDrop';
import { Link } from 'react-router-dom';


const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Nav() {
 
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className='nav-app-bar-con'>

    <AppBar sx={{backgroundColor: 'white'}} position="static">
      <Container sx={{paddingLeft: '2px',paddingRight: '2px'}}>
        <Toolbar disableGutters>
    
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
         <img className='logo' src='logo192.png' alt="MMT LOGO" />
          </Typography>


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              //onClick={handleOpenNavMenu}
              color="black"
            >
            <NavDrawer/>
            </IconButton>
            
          </Box>
         
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          <img className='logo' src='logo192.png' alt='MMT LOGO' />
          </Typography>


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
       {/*    <Button sx={{color:'rgb(92, 12, 12)',textTransform:'capitalize'}}>NISBA</Button> 
          <Button sx={{color:'rgb(92, 12, 12)',textTransform:'capitalize'}}>Innovation</Button>  
            */}
            <Link style={{textDecoration: 'none'}} to="https://institute.mmt-ng.com/">
            <Button sx={{color:'rgb(92, 12, 12)',textTransform:'capitalize'}}>MMT CENTER</Button> 
            </Link>
            {/* <InstituteDrop/> */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Login account">
            <Link style={{textDecoration: 'none'}} to="https://institute.mmt-ng.com/">
            <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '10px',marginRight:'5px', backgroundColor:'rgb(92, 12, 12)'}}  size="small" variant="contained" endIcon={''}>
            Master coding Enroll now
            </Button>
            </Link>
           
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar" 
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>


        </Toolbar>
      </Container>
    </AppBar>

    </div>
  );
}
export default Nav