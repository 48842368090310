import React from 'react';
import {BrowserRouter} from 'react-router-dom'
import './App.css';
import './mediaQueries.css'
import RoutesRoot from './router/RoutesRoot';


function App() {
  return (
    <BrowserRouter>
    
    <div className="App">
      
      <RoutesRoot/>
   
    </div>
    </BrowserRouter>
  );
}

export default App;
