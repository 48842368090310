import * as React from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';


function TransitionLeft(props) {
  return <Slide  sx={{background: "rgb(92, 12, 12)"}} className='Hover-elemnt bg-white text-black font-extrabold' {...props} direction="left" />;
}



export default function AlertPOP({message,Open,transition,handleClose,colors}) {

  
  return (
    <Box sx={{ width: 300 }}>
      <Snackbar
        open={Open}
        onClose={handleClose}
        TransitionComponent={transition === "TransitionLeft" && TransitionLeft}
        message={message}
        key={transition === "TransitionLeft" ? transition.name : ''}
      />
    </Box>
  );
}