import React from 'react'
import MovingComponent from 'react-moving-text'
import ImgSilder from './ImgSilder'
import MMTText from './MMTText'


function MMT() {

  return (
    <div className='MMT-con'>
      <div className='wave-con'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="rgba(0, 0, 0, 0.082)" fill-opacity="1" d="M0,96L48,112C96,128,192,160,288,192C384,224,480,256,576,240C672,224,768,160,864,133.3C960,107,1056,117,1152,117.3C1248,117,1344,107,1392,101.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
      </div>

      <h1>
    <MovingComponent
    type="bounce"
    duration="10s"
    delay="10s"
    direction="normal"
    timing="ease"
    iteration="infinite"
    fillMode="none">
    <span style={{color:'red'}}>Mas</span><span style={{color:'black'}}>ter</span><span style={{color:'#0099ff'}}>Mind</span><span style={{color:'black'}}>Tech</span>
  </MovingComponent>  
    </h1>

    <div className='MMT-inner-con'>
        <ImgSilder/>
    </div>
    
    <div className='MMT-text-inner-con'>

      <MMTText/>
    
    </div>




    </div>
  )
}

export default MMT