import React from 'react'
import CourseCard from './CourseCard'
import MTCNav from './MTCNav'

function MmtTrainingCenter() {
  return (
    <div className='mmt-center-con'>

          <div className='mtc-header'>
          <div className='mtc-nav'>

             <MTCNav/>
          </div>
          </div>
          <div className='mtc-section-1'></div>
          <div className='mtc-aside'>
             <div className='inner-aside'>list</div>
          </div>
          <div className='mtc-section'>
              <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
               <CourseCard
              CourseName="Web Development"
              CourseDescription=""
              />
          </div>
          <div className='mtc-footer'></div>







    </div>
  )
}

export default MmtTrainingCenter