import React from 'react'
import { Helmet } from 'react-helmet'

function SupernovaText() {
  return (
    <div className='supernova-con'> 
    
     <Helmet>
       <meta  name='description' content="MMT Training center that provides a trainee good aspect in practical programming language. The trainee will also benefit the training center by engaging with group and participation. Joining the training center we focus on practical training, so the trainee will have firsthand experience in handling software. As well as being familiar with the programming languages that they learn in the center." />
     </Helmet>

  <p>
     <h3>MastermindTech Mission</h3>

     MMT Mission is to establish an everlasting Tech Institute that will provide a trainee a good aspect in practical learning and Innovation. Moreover, to provide solutions that will benefit the society and business owners.  We focused on practical training, so that the trainee will have firsthand experience in handling software as well as being familiar with the programming languages that they learn in the center.

  </p>

 <p>

    <p>
        <h3>MastermindTech Vision</h3>
        Our vision is to provide excellent knowledge and skills to students who participate in the center, and many opportunities that will arise. From Web Development, Software Engineering, Android/IOS Development, Networking, Freelancing Concept, Content Marketing, Digital Marketing etc.
    </p>

 <h3>MMT CENTER</h3>

 MMT Training center provides a trainee good aspect in practical programming language. The trainee will also benefit the training center by engaging with group and participation. Joining the training center we focus on practical training, so the trainee will have firsthand experience in handling software. As well as being familiar with the programming languages that they learn in the center.
 </p>


 <p>
 The individuals will have the opportunity to enhance their skills through homework and projects. Training center will ensure 90% of the courses will be practical other than taking theories. We offer not just training for the programming but also for the students, as we want to instill confidence in their work. 
 </p>
 
  <p>
  Students will work hand in hand with their supervisor, the supervisor will always check if their homework or project is finished. Teamwork is an essential part of the training center, being able to do the activities as well as help out your fellow peers is important. Our aim is to give open doors of opportunity for students taking the courses, and with that there is no better beginning than starting with MastermindTech.
  </p>


   <p>
     <h3>MMT CENTER</h3>
     MMT’s CENTER offers a chance for students who are willing to give their time for learning,in order  to help them to brighten their future. Perks given carry you as far as you push it, the basics are taught and enforced, but ultimately it’s up to the student to utilize the skills wisely. Many courses will be available and students will have to choose. Time is your responsibility, and we promise once you attend these classes you’ll leave with enlightenment.

   </p>

   <p>
     <h3>PROGRAMS</h3>
     
     MMT CENTER provides modules, training sessions and programming courses in physical laboratories and online Labs, that allow students to enroll into their desired profession according to the length of the program. MMT CENTER has a long duration program of up to 7 months of learning and practicing code. Through this technique give a student core foundation in programming language and also acquired good skills, the training center focus on delivering standard training curriculum for which is any student wants to learn, Android Development, Web Development, Desktop Development, Hybrid Application, will register for a 7 months program that can give that person solid foundation in a concentrated area.

  <p>
    
Furthermore, there is a short course training for individuals who want to acquire basic Computer Knowledge. The training center focus in offering Microsoft Office-Certificate, Computer Maintenance and Human Resources Certificate. Moreover, there will be additional programs in the future.</p> 

   </p>

  <p>
    <h3>What will the programming languages provide?</h3>

    The programming languages according to the profession, the person who needs to learn web design or development has to go through the specific programming languages. For instance, if someone wants to learn web design they have to go through HTML, CSS, and JavaScript etc. Someone who wants to be a full-stack web developer has to learn frontend and backend languages. These languages will carry you according to what path you take. The languages will go more in depth according to the courses that are chosen. Suffice to say languages, in some way shape or form, are used all the time throughout the courses. Heavy emphasis will be put on the peers being able to grasp the basic understanding of how to use language appropriately.

  </p>

  <p>
    <h3>What will the course modules look like?</h3>
    The course modules will provide the schedules for when the classes will take place. More details will be displayed on the website. Due to the courses and programs taken, the student will look up to between 2 weeks to 7 months entirely depending on what they choose. Schedules will not change, so we HIGHLY advise the student to make free time for the extra courses. If the student is absent for 2 days, they will be exempted from the lesson and will have to apply again afterward. We encourage effort and time efficiency, pushing our peers to become punctual as well as responsible

  </p>

  <p>
    <h3>How long will the courses last?</h3>
    Each course has its own time schedule. Depending on the course you take, the time will vary. Some courses will last longer than others due to the difficulty.  The student will have the choice to pick and choose the course they desire, as well as any questions about the courses will be answered. If the student is uncertain whether they are unable to join due to time contingencies, they will have to contact the supervisor or the academic affairs of the training center. Extra courses will be provided if agreed upon to students who have a genuine problem with the course module.

  </p>

  <p>
    <h3>What are the benefits of training in our center?</h3>

    The student, depending on the course they took, will be able to make better decisions and have a better idea of their choice. Moreover, they’ll be able to use these skills outside in the real world, therefore making it easier to obtain jobs and ever broaden their horizons. We aim not just to teach, but to encourage and push students in the right direction to their life goals. We promise by the end of the training, many doors of opportunities will be open. Skills will be given to you, however the road to success will depend entirely on your choices you pave yourself with. This extracurricular activity entirely depends on the effort made.

  </p>

  <p>
    <h3>What do we value?</h3>
    Punctuality, commitment, consistency, integrity, and above all else effort.  Teamwork/ group work will be common amongst peers to encourage team building and sharing ideas, as well as issues to steer clear of any problem that arises. The main goal of our values is to benefit the individual as well as giving the confidence to help others as well. Being able to support your fellow peers is just as important as doing the tasks that are given. Please practice these values, and your way to professionalism will be quick and rewarding.

  </p>

  <p>
    <h3>What type of equipment will we provide for the courses?</h3>
    Computer lab, projectors, board, Text-books, Wi-Fi and other necessary equipment. The student will focus on the provided items and know how to use them confidently. They will become familiar and will spend most of their time using the given items. However, the students will mostly focus on computers and how to use them efficiently.  At the end of the training, peers will be able to utilize the items to their full capacity. Little emphasis will be put on the hardware and instead be more inclined to learn the software.

  </p>


  <p>
    <h3>How do we focus on the practices?</h3>
    Students will have ample opportunity to practice through assignments, projects, group work etc. There will be heavy incentives on making sure the students are able to work efficiently and effectively. Depending on the course the practice will change accordingly, however the main idea of such emphasis is to instill the confidence and knowledge to be able to perform exceedingly. At the end of the course, peers should have a good idea of the practices, and use the skills for the real world through job opportunities or personal innovation.

  </p>

  <p>
    <h3>How do you obtain our certificate?</h3>
    There will be a final project which will be the final barrier to obtaining your certificate. Students will have to submit their final project to their supervisor to show their overall skills and ideas. The project will be individual and not as a group. This is to show us what you learned personally and see if you truly understand what you learned through the training program. Benefits of doing the project may also be used for other means of advertisement or anything the student desires. Overall, this will carry on and can be used as a portfolio for future references to companies to better plan their path of success.

  </p>


    </div>
  )
}

export default SupernovaText