import React from 'react'
import Footer from './components/Footer'
import Header from './components/Header'
import Section from './components/Section'

function HomePage() {
  return (
    <div className='Home-Page-Container'>

      <Header/> 
      <Section/>
      <Footer/>
       
    </div>
  )
}

export default HomePage