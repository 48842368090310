import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SupernovaText from './SupernovaText';
import NisbaText from './NisbaText';
import MastermindTechText from './MastermindTechText';
import { Link } from 'react-router-dom';

export default function PopServiceCard(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleClickOpen('paper')}>Read More</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
         <div style={{justifyContent:"space-between"}} className='flex '>
        <DialogTitle id="scroll-dialog-title">{props.serviceName}</DialogTitle>
          { 
             props.serviceName === "MMT CENTER" &&
           <DialogTitle id="scroll-dialog-title"> <Link style={{textDecoration: 'none'}} to="https://institute.mmt-ng.com/">Visit Site</Link></DialogTitle>
          }

            { 
             props.serviceName === "NISBA" &&
           <DialogTitle id="scroll-dialog-title"> <Link style={{textDecoration: 'none'}} to="https://nisba.mmt-ng.com/">Visit Site</Link></DialogTitle>
          }

         </div>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            sx={{textAlign:'justify',fontFamily: 'Courier New'}}
            tabIndex={-1}
            >
              {
                props.serviceName === "NISBA" &&  <NisbaText/> 
              }
              {
                props.serviceName === "MMT CONSULT" &&  <MastermindTechText/> 
              }
              {
                props.serviceName === "MMT CENTER" &&  <SupernovaText/> 
              }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}