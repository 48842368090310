import React from 'react'
import MovingComponent from 'react-moving-text'
import ImgSilder from './ImgSilder'
import InnovationSlider from './InnovationSlider'
import MMTText from './MMTText'
import ServiceCard from './ServiceCard'


function Innovation() {

  return (
    <div className='Innovation-con'>
 
        
        <h1 className='header-text'>
    <MovingComponent
    type="bounce"
    duration="10s"
    delay="10s"
    direction="normal"
    timing="ease"
    iteration="infinite"
    fillMode="none">
    <span style={{color:'red'}}>In</span><span style={{color:'black'}}>no</span><span style={{color:'#0099ff'}}>va</span><span style={{color:'black'}}>tions</span>
  </MovingComponent>  
    </h1>
     
    <div className='Innovation-inner-con'>
       
       <InnovationSlider/>
      
    </div>





    </div>
  )
}

export default Innovation