const CountriesList = [
    {
      value: 'Afghanistan',
      label: '+93',
    },
    {
      value: 'Albania',
      label: '+355',
    },
    {
      value: 'Algeria',
      label: '+213',
    },
    {
      value: 'Andorra',
      label: '+376',
    },
    {
      value: 'Angola',
      label: '+244',
    },
    {
      value: 'Antigua and Barbuda',
      label: '+1-268',
    },
    {
      value: 'Argentina',
      label: '+54',
    },
    {
      value: 'Armenia',
      label: '+374',
    },
    {
      value: 'Australia',
      label: '+61',
    },
    {
      value: 'Austria',
      label: '+43',
    },
    {
      value: 'Azerbaijan',
      label: '+994',
    },
    {
      value: 'Bahamas',
      label: '+1-242',
    },
    {
      value: 'Bahrain',
      label: '+973',
    },
    {
      value: 'Bangladesh',
      label: '+880',
    },
    {
      value: 'Barbados',
      label: '+1-246',
    },
    {
      value: 'Belarus',
      label: '+375',
    },
    {
      value: 'Belgium',
      label: '+32',
    },
    {
      value: 'Belize',
      label: '+501',
    },
    {
      value: 'Benin',
      label: '+229',
    },
    {
      value: 'Bhutan',
      label: '+975',
    },
    {
      value: 'Bolivia',
      label: '+591',
    },
    {
      value: 'Bosnia and Herzegovina',
      label: '+387',
    },
    {
      value: 'Botswana',
      label: '+267',
    },
    {
      value: 'Brazil',
      label: '+55',
    },
    {
      value: 'Brunei',
      label: '+673',
    },
    {
      value: 'Bulgaria',
      label: '+359',
    },
    {
      value: 'Burkina Faso',
      label: '+226',
    },
    {
      value: 'Burundi',
      label: '+257',
    },
    {
      value: 'Cabo Verde',
      label: '+238',
    },
    {
      value: 'Cambodia',
      label: '+855',
    },
    {
      value: 'Cameroon',
      label: '+237',
    },
    {
      value: 'Canada',
      label: '+1',
    },
    {
      value: 'Central African Republic',
      label: '+236',
    },
    {
      value: 'Chad',
      label: '+235',
    },
    {
      value: 'Chile',
      label: '+56',
    },
    {
      value: 'China',
      label: '+86',
    },
    {
      value: 'Colombia',
      label: '+57',
    },
    {
      value: 'Comoros',
      label: '+269',
    },
    {
      value: 'Congo (Congo-Brazzaville)',
      label: '+242',
    },
    {
      value: 'Costa Rica',
      label: '+506',
    },
    {
      value: 'Croatia',
      label: '+385',
    },
    {
      value: 'Cuba',
      label: '+53',
    },
    {
      value: 'Cyprus',
      label: '+357',
    },
    {
      value: 'Czech Republic',
      label: '+420',
    },
    {
      value: 'Democratic Republic of the Congo',
      label: '+243',
    },
    {
      value: 'Denmark',
      label: '+45',
    },
    {
      value: 'Djibouti',
      label: '+253',
    },
    {
      value: 'Dominica',
      label: '+1-767',
    },
    {
      value: 'Dominican Republic',
      label: '+1-809, +1-829, +1-849',
    },
    {
      value: 'East Timor (Timor-Leste)',
      label: '+670',
    },
    {
      value: 'Ecuador',
      label: '+593',
    },
    {
      value: 'Egypt',
      label: '+20',
    },
    {
      value: 'El Salvador',
      label: '+503',
    },
    {
      value: 'Equatorial Guinea',
      label: '+240',
    },
    {
      value: 'Eritrea',
      label: '+291',
    },
    {
      value: 'Estonia',
      label: '+372',
    },
    {
      value: 'Eswatini',
      label: '+268',
    },
    {
      value: 'Ethiopia',
      label: '+251',
    },
    {
      value: 'Fiji',
      label: '+679',
    },
    {
      value: 'Finland',
      label: '+358',
    },
    {
      value: 'France',
      label: '+33',
    },
    {
      value: 'Gabon',
      label: '+241',
    },
    {
      value: 'Gambia',
      label: '+220',
    },
    {
      value: 'Georgia',
      label: '+995',
    },
    {
      value: 'Germany',
      label: '+49',
    },
    {
      value: 'Ghana',
      label: '+233',
    },
    {
      value: 'Greece',
      label: '+30',
    },
    {
      value: 'Grenada',
      label: '+1-473',
    },
    {
      value: 'Guatemala',
      label: '+502',
    },
    {
      value: 'Guinea',
      label: '+224',
    },
    {
      value: 'Guinea-Bissau',
      label: '+245',
    },
    {
      value: 'Guyana',
      label: '+592',
    },
    {
      value: 'Haiti',
      label: '+509',
    },
    {
      value: 'Honduras',
      label: '+504',
    },
    {
      value: 'Hungary',
      label: '+36',
    },
    {
      value: 'Iceland',
      label: '+354',
    },
    {
      value: 'India',
      label: '+91',
    },
    {
      value: 'Indonesia',
      label: '+62',
    },
    {
      value: 'Iran',
      label: '+98',
    },
    {
      value: 'Iraq',
      label: '+964',
    },
    {
      value: 'Ireland',
      label: '+353',
    },
    {
      value: 'Israel',
      label: '+972',
    },
    {
      value: 'Italy',
      label: '+39',
    },
    {
      value: 'Jamaica',
      label: '+1-876',
    },
    {
      value: 'Japan',
      label: '+81',
    },
    {
      value: 'Jordan',
      label: '+962',
    },
    {
      value: 'Kazakhstan',
      label: '+7',
    },
    {
      value: 'Kenya',
      label: '+254',
    },
    {
      value: 'Kiribati',
      label: '+686',
    },
    {
      value: 'Kosovo',
      label: '+383',
    },
    {
      value: 'Kuwait',
      label: '+965',
    },
    {
      value: 'Kyrgyzstan',
      label: '+996',
    },
    {
      value: 'Laos',
      label: '+856',
    },
    {
      value: 'Latvia',
      label: '+371',
    },
    {
      value: 'Lebanon',
      label: '+961',
    },
    {
      value: 'Lesotho',
      label: '+266',
    },
    {
      value: 'Liberia',
      label: '+231',
    },
    {
      value: 'Libya',
      label: '+218',
    },
    {
      value: 'Liechtenstein',
      label: '+423',
    },
    {
      value: 'Lithuania',
      label: '+370',
    },
    {
      value: 'Luxembourg',
      label: '+352',
    },
    {
      value: 'Madagascar',
      label: '+261',
    },
    {
      value: 'Malawi',
      label: '+265',
    },
    {
      value: 'Malaysia',
      label: '+60',
    },
    {
      value: 'Maldives',
      label: '+960',
    },
    {
      value: 'Mali',
      label: '+223',
    },
    {
      value: 'Malta',
      label: '+356',
    },
    {
      value: 'Marshall Islands',
      label: '+692',
    },
    {
      value: 'Mauritania',
      label: '+222',
    },
    {
      value: 'Mauritius',
      label: '+230',
    },
    {
      value: 'Mexico',
      label: '+52',
    },
    {
      value: 'Micronesia',
      label: '+691',
    },
    {
      value: 'Moldova',
      label: '+373',
    },
    {
      value: 'Monaco',
      label: '+377',
    },
    {
      value: 'Mongolia',
      label: '+976',
    },
    {
      value: 'Montenegro',
      label: '+382',
    },
    {
      value: 'Morocco',
      label: '+212',
    },
    {
      value: 'Mozambique',
      label: '+258',
    },
    {
      value: 'Myanmar (formerly Burma)',
      label: '+95',
    },
    {
      value: 'Namibia',
      label: '+264',
    },
    {
      value: 'Nauru',
      label: '+674',
    },
    {
      value: 'Nepal',
      label: '+977',
    },
    {
      value: 'Netherlands',
      label: '+31',
    },
    {
      value: 'New Zealand',
      label: '+64',
    },
    {
      value: 'Nicaragua',
      label: '+505',
    },
    {
      value: 'Niger',
      label: '+227',
    },
    {
      value: 'Nigeria',
      label: '+234',
    },
    {
      value: 'North Korea',
      label: '+850',
    },
    {
      value: 'North Macedonia',
      label: '+389',
    },
    {
      value: 'Norway',
      label: '+47',
    },
    {
      value: 'Oman',
      label: '+968',
    },
    {
      value: 'Pakistan',
      label: '+92',
    },
    {
      value: 'Palau',
      label: '+680',
    },
    {
      value: 'Palestine State',
      label: '+970',
    },
    {
      value: 'Panama',
      label: '+507',
    },
    {
      value: 'Papua New Guinea',
      label: '+675',
    },
    {
      value: 'Paraguay',
      label: '+595',
    },
    {
      value: 'Peru',
      label: '+51',
    },
    {
      value: 'Philippines',
      label: '+63',
    },
    {
      value: 'Poland',
      label: '+48',
    },
    {
      value: 'Portugal',
      label: '+351',
    },
    {
      value: 'Qatar',
      label: '+974',
    },
    {
      value: 'Romania',
      label: '+40',
    },
    {
      value: 'Russia',
      label: '+7',
    },
    {
      value: 'Rwanda',
      label: '+250',
    },
    {
      value: 'Saint Kitts and Nevis',
      label: '+1-869',
    },
    {
      value: 'Saint Lucia',
      label: '+1-758',
    },
    {
      value: 'Saint Vincent and the Grenadines',
      label: '+1-784',
    },
    {
      value: 'Samoa',
      label: '+685',
    },
    {
      value: 'San Marino',
      label: '+378',
    },
    {
      value: 'Sao Tome and Principe',
      label: '+239',
    },
    {
      value: 'Saudi Arabia',
      label: '+966',
    },
    {
      value: 'Senegal',
      label: '+221',
    },
    {
      value: 'Serbia',
      label: '+381',
    },
    {
      value: 'Seychelles',
      label: '+248',
    },
    {
      value: 'Sierra Leone',
      label: '+232',
    },
    {
      value: 'Singapore',
      label: '+65',
    },
    {
      value: 'Slovakia',
      label: '+421',
    },
    {
      value: 'Slovenia',
      label: '+386',
    },
    {
      value: 'Solomon Islands',
      label: '+677',
    },
    {
      value: 'Somalia',
      label: '+252',
    },
    {
      value: 'South Africa',
      label: '+27',
    },
    {
      value: 'South Korea',
      label: '+82',
    },
    {
      value: 'South Sudan',
      label: '+211',
    },
    {
      value: 'Spain',
      label: '+34',
    },
    {
      value: 'Sri Lanka',
      label: '+94',
    },
    {
      value: 'Sudan',
      label: '+249',
    },
    {
      value: 'Suriname',
      label: '+597',
    },
    {
      value: 'Sweden',
      label: '+46',
    },
    {
      value: 'Switzerland',
      label: '+41',
    },
    {
      value: 'Syria',
      label: '+963',
    },
    {
      value: 'Taiwan',
      label: '+886',
    },
    {
      value: 'Tajikistan',
      label: '+992',
    },
    {
      value: 'Tanzania',
      label: '+255',
    },
    {
      value: 'Thailand',
      label: '+66',
    },
    {
      value: 'Togo',
      label: '+228',
    },
    {
      value: 'Tonga',
      label: '+676',
    },
    {
      value: 'Trinidad and Tobago',
      label: '+1-868',
    },
    {
      value: 'Tunisia',
      label: '+216',
    },
    {
      value: 'Turkey',
      label: '+90',
    },
    {
      value: 'Turkmenistan',
      label: '+993',
    },
    {
      value: 'Tuvalu',
      label: '+688',
    },
    {
      value: 'Uganda',
      label: '+256',
    },
    {
      value: 'Ukraine',
      label: '+380',
    },
    {
      value: 'United Arab Emirates',
      label: '+971',
    },
    {
      value: 'United Kingdom',
      label: '+44',
    },
    {
      value: 'United States',
      label: '+1',
    },
    {
      value: 'Uruguay',
      label: '+598',
    },
    {
      value: 'Uzbekistan',
      label: '+998',
    },
    {
      value: 'Vanuatu',
      label: '+678',
    },
    {
      value: 'Vatican City',
      label: '+379',
    },
    {
      value: 'Venezuela',
      label: '+58',
    },
    {
      value: 'Vietnam',
      label: '+84',
    },
    {
      value: 'Yemen',
      label: '+967',
    },
    {
      value: 'Zambia',
      label: '+260',
    },
    {
      value: 'Zimbabwe',
      label: '+263',
    },
  ];


  export default CountriesList