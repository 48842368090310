import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";



// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import InnovationSliderCard from "./InnovationSliderCard";

export default function InnovationSlider() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 6900,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><InnovationSliderCard 
        imgPath='ras1.jpg'
        header="Creativity"
        text="MastermindTech Institute offers innovative programs a chance for students who are willing to give their time for learning, to help them to brighten their future. Perks given carry you as far as you push, the basics are taught and enforced, but ultimately it’s up to the student to utilize the skills wisely. Many courses will be available and students will have to choose. Time is your responsibility, and we promise once you attend these classes you’ll leave with enlightenment.
        "
        /></SwiperSlide>
        <SwiperSlide><InnovationSliderCard
         imgPath='ar8.webp'
         header="IOT Programs"
         text="MastermindTech provides modules, training sessions, and programming courses in physical laboratories and online Labs, that allow students to enroll in their desired profession according to the length of the program. MMT has a long-duration program of up to 7 months of learning and practicing code. This technique gives a student a core foundation in programming language and also acquired good skills
        "
         /></SwiperSlide>
      </Swiper>
    </>
  );
}
