import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import MovingComponent from 'react-moving-text';
import CountriesList from './CountriesList';
import AlertPOP from '../../../router/AlertPOP';
import baseUrl from '../../../BaseURL';
import validator from 'validator';

function Institute() {

 


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    subject: '',
    message: ''
  });

  const [SendStatus,setSendStatus] = useState(true)


    
  const [message,setmessage] = useState("")
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);

 
  // Handle the alert transition and function

  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpen(true);
  };
   // Handle the close alert box
  const handleClose = () => {
    setOpen(false);
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
     // Call alert box function
     handleClick("left")
    // Check if any of the fields are empty
    const isEmpty = Object.values(formData).some((value) => value.trim() === '');

    if (isEmpty) {
      setmessage('Please fill in all fields');
      return
    } 

    if(formData.phoneNumber.startsWith("+")) {

      setmessage("Mobile phone number cannot start with + or = , select country code aside");
      return
    }

    setSendStatus(false)

    axios.post(`${baseUrl}/pub/contactus`,formData,{
       withCredentials: true,
       headers: {
         'Accept' : 'application/json'
       }}).then(res=>{

              if(res.data.status){

                   setmessage(res.data.textStatus)
                   setSendStatus(true)
              }


       }).catch(err=>{
 
            if(err) console.log(err)
            setmessage(err.message)
            setSendStatus(true)
           
      })


  };

  return (
    <div className='institute-con'>
       <div style={{position:"absolute"}} className=''>
       <AlertPOP
       Open={open}
       handleClose={handleClose}
       transition={transition}
       message={message}
      />
       </div>
      <div className='inner-institute-con'>
        {/* -------Inner side text con -----------------*/}
        <div className='inner-ins-side-text-con'>
          <h1>
            <MovingComponent
              type="typewriter"
              dataText={[
                "We're Creative Technological Center",
              'Believing In Yourself Is The First Secret To Success.',
              'Never Stop Learning Because Life Never Stop Teaching.',
              'The Indispensable Requirement For Hard Work Is Self-discipline.',
              'Success Is Possible Only When You Can Overcome The Natural Tendency To Cut Corners And Take The Easy Way',
              'Lasting Success Is Possible Only When You Can Discipline Yourself To Work Hard For a Long Long Time.',
              'نحن وكالة للخلق التكنولوجيا',
              'الاعتقاد الشخصي  هو السر الأول لنجاحك',
              'لا تتوقف أبدا عن التعلم  لأن الحياءلا تتوقف أبدا عن التدريس',
              "الشرط للغني العمل الجاد هو الإنضباط الذاتي",
              "النجاح ممكن فقط عندما تتمكن من التغلب علي الميل الطبيعي لقطع الزوايا وإتخاذ الطريق السهل",
              "لا يمكن إدراج النجاح إلا إذا عندما ضبط نفسك للعمل لفترة طويلة",
              'Yarda Da Kai Shine Sirrin Farkon samun Nasara.',
              'Kar Ka/Ki Taba Daina Kuyan Abu,Saboda Rayuwa Bata Taba Daina Kuyarwa.',
              ]}
            />
          </h1>
        </div>
        {/* ---------  End --------*/}
        {/* -------------  Inner side form con  --------- */}
        <div className='inner-ins-side-form-con'>
          <h1>CREATIVE TECHNOLOGICAL CENTER</h1>
          <div className='ins-form-con'>
            <form onSubmit={handleSubmit}>
              <Stack spacing={1} direction="row">
                <Typography className='explore-text' sx={{ position: 'relative', padding: '10px' }} variant="h6" component="h6">
                  Explore More With MasterMindTech
                  <ArrowCircleDownOutlinedIcon className="expl-icon" sx={{ marginTop: '4px', color: "rgb(92, 12, 12)" }} />
                </Typography>
              </Stack>
              <Stack className='stack-con' sx={{ width: '100%' }} spacing={2} direction="row">
                <TextField sx={{ width: '50%' }} id="firstName" name="firstName" label="First Name" variant="outlined" value={formData.firstName} onChange={handleInputChange} />
                <TextField sx={{ width: '50%' }} id="lastName" name="lastName" label="Last Name" variant="outlined" value={formData.lastName} onChange={handleInputChange} />
              </Stack>
              <Stack className='stack-con' spacing={0} direction="row">
                <TextField sx={{ width: '100%' }} id="email" name="email" type='email' label="Email Address" variant="outlined" value={formData.email} onChange={handleInputChange} />
              </Stack>
              <Stack className='stack-con' spacing={2} direction="row">
                <TextField
                  id="outlined-select-currency"
                  name="countryCode"
                  select
                  label="Country Code"
                  value={formData.countryCode}
                  onChange={handleInputChange}
                  defaultValue=""
                  helperText=""
                  sx={{ width: '40%' }}
                >
                  {CountriesList.map((option) => (
                    <MenuItem key={option.value} value={option.value + " " + option.label}>
                      {option.value} {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField sx={{ width: '60%' }} id="phoneNumber" name="phoneNumber" label="phone number" variant="outlined" value={formData.phoneNumber} onChange={handleInputChange} />
              </Stack>
              <Stack className='stack-con' spacing={2} direction="row">
                <TextField sx={{ width: '100%' }} id="subject" name="subject" label="Subject" variant="outlined" value={formData.subject} onChange={handleInputChange} />
              </Stack>
              <Stack className='stack-con' spacing={2} direction="row">
                <TextField
                  id="outlined-multiline-static"
                  name="message"
                  label="Quick Message"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={formData.message}
                  onChange={handleInputChange}
                  sx={{ width: '100%' }}
                />
              </Stack>
              <Stack className='stack-con' spacing={2} direction="row">
                <Button className='Hover-elemnt' type="submit" sx={{ backgroundColor: 'rgb(92, 12, 12)', width: '100%', padding: '15px' }} variant="contained">{SendStatus ? "Quick Request" : "Sending..."}</Button>
              </Stack>
            </form>
          </div>
        </div>
        {/* -------------End ------------- */}
      </div>
    </div>
  );
}

export default Institute;