import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

function NisbaText() {
  return (
    <div>

    <Helmet>
       <meta  name='description' content="Nisba is a Real State company under MastermindTech, The company focuses on providing simplified software for real estate businesses. Its current software simplified the manual process of finding rental apartments, Houses, Lands, and many more. You can download the application from PlayStore or Apple Store and start using it immediately. The App name is called (Nisba)" />
     </Helmet>


      <p>
        <h3>NISBA</h3>

        Automatically after signing up, we verify the client through a process called (KYC) information and authorize the client registration. For more information, you can visit official Nisba website  <Link to="nisba.mmt-ng.com" >click to visit</Link>
         
       

      </p>




    </div>
  )
}

export default NisbaText