import React from 'react'

function InnovationSliderCard(props) {
  return (
    <div className='InnovationSliderCard-con'>

     <div className='inno-card-img-con'>
       <img src={props.imgPath}/>
     </div>

     <div className='inno-card-text-con'>

        <h1>{props.header}</h1>
        <p>{props.text}</p>

     </div>


    </div>
  )
}

export default InnovationSliderCard