import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

const steps = [
  {
    label: "Who we are ?",
    description: `The team is a dedicated group of individuals who specialize in Computer field and Business Information System. Executives have all skills that are used to broaden the company, every special skill has its own place in MastermindTech. The young, albeit ambitious, can have a place in MMT. MastermindTech encourages new ideas and projects that can help improve the simplicity of life around the world. Cared for deeply by the executives, the goal for MastermindTech will broaden the horizon for many of those who wish to find the correct path of success, as well as offering many of our audiences.`,
  },
  {
    label: 'MastermindTech Mission',
    description:
      'Our Mission is to establish an everlasting Tech Institute that will provide a trainee a good aspect in practical learning and Innovation. Moreover, to provide solutions that will benefit the society and business owners. We focused on practical training, so that the trainee will have firsthand experience in handling software as well as being familiar with the programming languages that they learn in the center.',
  },
  {
    label: 'MastermindTech Vision',
    description: `Our vision is to provide excellent knowledge and skills to students who participate in the center, and many opportunities that will arise. From Web Development, Software Engineering, Android/IOS Development, Networking, Freelancing Concept, Content Marketing, Digital Marketing etc.`,
  },
  {
    label: 'Contact US',
    description: `WhatsApp: +2347040768898, For-Call:+2347040768898, Email: info@mmt-ng.com`,
  },
];

export default function MMTText() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box className="mmt-stepper-con" sx={{ maxWidth: 400 }}>
        <Helmet>
           <h1>MasterMindTech</h1>
           <meta name='description' content="Our vision is to provide excellent knowledge and skills to students who participate in our training center, and many opportunities that will arise. We are devoted to providing solutions to enterprises, Governmental Sectors, Private Institutes, organizations, schools, Universities, hospital systems etc. Overall, our main goal is to be a stable technology company that aspires to solutions." />
        </Helmet>
      <Stepper activeStep={activeStep}  orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              
              optional={
                index === 2 ? (
                  <Typography variant="caption">To the Last Step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography sx={{textAlign:'justify',fontFamily: 'Menlo',zIndex:2}}>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Proceed'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1}}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>click reset to read again</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}