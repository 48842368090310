import React from 'react'
import Innovation from './Section/Innovation'
import Institute from './Section/Institute'
import MMT from './Section/MMT'
import Services from './Section/Services'
import WhatWeValue from './Section/WhatweValue'
import ContactUs from './Section/ContactUs'

function Section() {
  return (
    <div className="section">
      
      <Institute/> 
      <MMT/> 
      <Services/>
      <WhatWeValue/> 
     <Innovation/>  
     <ContactUs/>  
    </div>
  )
}

export default Section