import React from 'react'
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

function NavList() {
  return (
    <div className='mtc-navlist-con'>

       <ul>
         <li style={{display: 'flex'}}>Certification Courses <CardMembershipIcon/></li>
         <li style={{display: 'flex'}}>Workshops <WorkspacePremiumIcon/></li>
       </ul>

    </div>
  )
}

export default NavList