import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const steps = [
  {
    label: 'MastermindTech',
    description: `We offer not just professional training but also with many services, Web-Hosting, Real-Estate, Software Development and many more.
    `,
  },
  {
    label: 'MMT CENTER',
    description:
      'MMT Training center provides a trainee good aspect in practical programming language. Joining the training center we focus on practical training, so the trainee will have firsthand experience in handling software. As well as being familiar with the programming languages that they learn in the center. kindly visit the page to enroll',
  },
  {
    label: 'Nisba',
    description: `Best platform for real estate business, download our app in PlayStore or Apple Store`,
  },
  {
    label: 'Contact US',
    description: `WhatsApp: +2347040768898, For-Call:+2347040768898, Email: info@mmt-ng.com`,
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box className="whatWevalue-stepper-con" sx={{ maxWidth: 400 , marginBottom: '10rem'}}>
      <Stepper sx={{color: 'white'}} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step sx={{color: 'white !important'}} key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography  variant="caption">Last step</Typography>
                ) : null
              }
              sx={{color: 'white !important'}}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography sx={{color: 'white'}}>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}