import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MTCDrawer from './MTCDrawer';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Search from './Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import NavList from './NavList';

const pages = ['Programs', 'Solutions', 'Institute'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function MTCNav() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  

  return (
    <AppBar sx={{backgroundColor: 'white'}} position="static">
      <Container sx={{paddingLeft: '2px',paddingRight: '2px'}} maxWidth="xl">
        <Toolbar disableGutters>
    
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: '', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
         <img className='logo' src='logo192.png' />
          </Typography>



          <Box className="mtc-inner-nav-con" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }}}>
           
            <Link style={{textDecoration: 'none'}} to="/mmt-training-center">
            <Button sx={{color:'rgb(92, 12, 12)'}}>Categories <DirectionsIcon/></Button> 
            </Link>
            <Search/>
            {/* <InstituteDrop/> */}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
          <Search/>
          </Box>

          <Box  sx={{ flexGrow: 0 }}>
            <Tooltip title="Login account">
            <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '10px',marginRight:'5px', backgroundColor:'rgb(92, 12, 12)'}}  size="small" variant="contained" endIcon={''}>
                    Sign In
            </Button>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>


        </Toolbar>
      <NavList/>
      </Container>
    </AppBar>
  );
}
export default MTCNav