import React from 'react'
import MovingComponent from 'react-moving-text'
import ImgSilder from './ImgSilder'
import MMTText from './MMTText'
import ServiceCard from './ServiceCard'


function Services() {

  return (
    <div className='Service-con'>
      <div className='wave-con'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="rgba(0, 0, 0, 0.082)" fill-opacity="1" d="M0,64L0,256L288,256L288,192L576,192L576,32L864,32L864,96L1152,96L1152,288L1440,288L1440,0L1152,0L1152,0L864,0L864,0L576,0L576,0L288,0L288,0L0,0L0,0Z"></path></svg>
      </div>

    <h1 className='header-text'>
    <MovingComponent
    type="swing"
    duration="10s"
    delay="10s"
    direction="normal"
    timing="ease"
    iteration="infinite"
    fillMode="none">
    <span style={{color:'red'}}>Ser</span><span style={{color:'black'}}>vic</span><span style={{color:'#0099ff'}}>es</span>
    </MovingComponent>  
    </h1>

    <div className='Service-inner-con'>
      <ServiceCard
      img="logo.png"
      serviceName="MMT CENTER"
      serviceText="MMT Training center provides a trainee good aspect in practical programming language. The trainee will also benefit the training center by engaging with group and participation. Joining the training center we focus on practical training, so the trainee will have firsthand experience in handling software. As well as being familiar with the programming languages that they learn in the center."
      />
       <ServiceCard
       img="logo.png"
       serviceName="MMT CONSULT"
       serviceText="Our vision is to provide excellent knowledge and skills to students who participate in our training center, and many opportunities that will arise. We are devoted to providing solutions to enterprises, Governmental Sectors, Private Institutes, organizations, schools, Universities, hospital systems etc. Overall, our main goal is to be a stable technology company that aspires to solutions.
       "
       />
       <ServiceCard
        img="newnisbalogo.jpeg"
        serviceName="NISBA"
        serviceText="Nisba is a RealEstate service product under MastermindTech, NISBA focused on providing simplified software for real estate businesses. Its current software simplified the manual process of finding rental apartments, Houses, Lands, and many more. You can download the application from PlayStore or Apple Store and start using it immediately. The App name is (Nisba)"
       />
    </div>





    </div>
  )
}

export default Services