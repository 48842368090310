import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Link } from 'react-router-dom';


export default function NavDrawer(props) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        
          <ListItem  disablePadding>
            <Link to="https://institute.mmt-ng.com/" style={{ textDecoration:'none'}}>
            <ListItemButton>

              <ListItemIcon>
               <LocalLibraryIcon sx={{fontSize:'3em',color:'rgb(92, 12, 12)'}} />
              </ListItemIcon>

              <ListItemText sx={{color:'rgb(92, 12, 12)'}} primary="MMT CENTER" />

            </ListItemButton>
            </Link>


          </ListItem>

     {/*      <ListItem  disablePadding>
            
            <ListItemButton>
              <ListItemIcon>
               <OtherHousesIcon sx={{fontSize:'3em',color:'rgb(92, 12, 12)'}} />
              </ListItemIcon>
              <ListItemText sx={{color:'rgb(92, 12, 12)'}} primary="Nisba" />
            </ListItemButton>


          </ListItem>

          <ListItem  disablePadding>
            
            <ListItemButton>
              <ListItemIcon>
               <PsychologyIcon sx={{fontSize:'3em',color:'rgb(92, 12, 12)'}} />
              </ListItemIcon>
              <ListItemText sx={{color:'rgb(92, 12, 12)'}} primary="Innovation" />
            </ListItemButton>


          </ListItem>
 */}
  
      </List>


      <Divider />

    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button sx={{color:'rgb(92, 12, 12)'}} onClick={toggleDrawer(anchor, true)}><ViewListIcon /></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}