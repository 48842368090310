import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CourseCardPop from './CourseCardPop';


export default function CourseCard(props) {
     
      const {CourseName,CourseDescription} = props



  return (
    <Card sx={{ maxWidth: 250, zIndex: 3}} elevation={18}>
      <CardMedia
        sx={{ height: 140,position: 'relative' }}
        image="pic2.jpg"
        title=""
      >
       <span className='Price-text'>N10,000</span>
       <span className='status-text'>Sale</span>
       </CardMedia>
      <CardContent>
      <Typography variant="body2" color="text.secondary">
          Program
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          {CourseName}
        </Typography>
      
        <Typography sx={{display:'flex',gap: 2}} variant="body2" color="text.secondary">
             <CourseCardPop
              CourseName={CourseName}
             />
            <Button className='Hover-elemnt' elevation={10} sx={{whiteSpace: 'nowrap' , fontSize: '10px', backgroundColor:'rgb(92, 12, 12)'}}  size="large" variant="contained" endIcon={''}>
                    Add to Card
            </Button>
        </Typography>

      </CardContent>
      <CardActions>
       
      </CardActions>
    </Card>
  );
}