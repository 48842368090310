import React from 'react'
import {Routes,Route} from 'react-router-dom'
import MmtTrainingCenter from '../Client/components/Institute/MmtTrainingCenter';
import HomePage from '../Client/HomePage';
import DashBoardIndex from '../DashBoard/DashBoardIndex';
import ErrorPage from './ErrorPage';
function RoutesRoot() {
  return (
      <>
      <Routes>
       <Route path='/*' element={<HomePage/>} /> 
       <Route path='/mmt-training-center' element={<MmtTrainingCenter />} />
       <Route path='/dash' element={<DashBoardIndex/>} />
      </Routes>

     {/*  <Routes>
       <Route path='*' element={<ErrorPage/>} /> 
      </Routes>
 */}


    </>
  )
}

export default RoutesRoot