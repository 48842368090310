import React, { useEffect, useState } from 'react'
import MovingComponent from 'react-moving-text'
import ImgSilder from './ImgSilder'
import MMTText from './MMTText'
import ServiceCard from './ServiceCard'
import AnimatedText from 'react-animated-text-content';
import WhatWevalueSideTab from './WhatWevalueSideTab'


function WhatWeValue() {

   const [Textlist,setTextlist] = useState(
    [
      '1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint facere dolorem provident fugit alias  eaque voluptatem quis consectetur. Laudantium autem qui perferendis harum,',
      
 ]
   )
   const [count,setcount] = useState(0)

 /*    useEffect(() => {
      
      if(count < 0){
        setcount(Textlist.length - 1)
     }
     if(count > Textlist.length - 1){
       setcount(0)
     }
         
    },[count]) */

/*    useEffect(() =>{


    const timer = setInterval(()=>{

      setcount(count + 1)
      
  },6000)
  
   const call = () => clearInterval(timer) 
   
     
      return 

   },[]) */
 

  return (
    <div className='Whatwevalue-con'>

    <div className='Whatwevalue-inner-con'>
   
    <h1 className='wht-we-value-h1'>
      
    <AnimatedText
  
  type="words" 
  animation={{
    x: '200px',
    y: '-20px',
    scale: 1.1,
    ease: 'ease-in-out',
  }}
  animationType="rifle"
  interval={0.06}
  duration={0.8}
  tag="p"
  className="animated-paragraph"
  includeWhiteSpaces
  threshold={0.1}
  rootMargin="20%"
>
We are devoted to providing solutions to enterprises, Governmental Sectors, Private Institutes, organizations, schools, Universities, hospital systems etc. Overall, our main goal is to be a stable technology company that aspires to solutions.
</AnimatedText>
      
      </h1> 
      <WhatWevalueSideTab/>
    </div>



    </div>
  )
}

export default WhatWeValue